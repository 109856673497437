import React, { useEffect, useState } from 'react';
import Navigation from './Navigation';
import Hero from './Hero';
import Products from './Products'
import Contact from './Contact'
import Order from './Order';
import DbRoute from './DbRoute';
import DbRouteDetail from './DbRouteDetail';
import DbRouteCustomerDetail from './DbRouteCustomerDetail';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import emailjs from '@emailjs/browser';

const AppContent = () => {
  const [language, setLanguage] = useState('CZ');
  const navigate = useNavigate();
  const location = useLocation();
  const isDetailPage = location.pathname.includes('/detail/') || location.pathname.includes('/objednavky') || location.pathname.includes('/customer/');

  useEffect(() => {
    // Initialize EmailJS with public key
    emailjs.init({
      publicKey: 'T3lnzCT2B4HxSrG0R',
    });
  }, []);

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };

  useEffect(() => {
    console.log(language);
  }, [language]);

  const handleOrderLinkClick = () => {
    navigate('/objednavky');
  };

  return (
    <>
    <div className={isDetailPage ? 'detail-route content-wrapper' : 'content-wrapper'}>
      <Navigation onLanguageChange={handleLanguageChange} />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero language={language} />
              <Products language={language} />
              {/* <Order language={language} /> */}
              <Contact language={language} />
            </>
          }
        />
        <Route path="/objednavky" element={<DbRoute />} />
        <Route path="/detail/:id" element={<DbRouteDetail />} />
        <Route path="/customer/:id" element={<DbRouteCustomerDetail />} />
      </Routes>
      <div className="copyright-div">
        <span>© Morient s.r.o 2024</span>
      </div>
      <div className="order-link-div" onClick={handleOrderLinkClick}>
        <span>x</span>
      </div>
    </div>
    <div id="modal-root"></div>
    </>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;