import { Client, Databases, ID, Query } from 'appwrite';

const client = new Client();

client
    .setEndpoint('https://cloud.appwrite.io/v1')
    .setProject('66e3b65c001625925f03');

export const databases = new Databases(client);

export const addOrder = async (data) => {
    try {
        const result = await databases.createDocument(
            '66e56789001538be65a2',
            '66e567a0000f222877be',
            ID.unique(),
            data
        )
        console.log('Order data:', data);
        console.log('Appwrite result:', result);
        return result
    } catch (error) {
        console.error('Appwrite error:', error);
        console.log(error)
    }
}

// get info about an order from the order collection
export const getCustomer = async (documentId) => {
    try {
        const result = await databases.getDocument(
            '66e56789001538be65a2',  // database ID
            '66e567a0000f222877be',   // collection ID for orders
            documentId
        );
        return result;
    } catch (error) {
        console.log('Error getting customer: ', error);
        throw error;
    }
}

export const getPw = async () => {
    try {
        const result = await databases.listDocuments(
            '66e56789001538be65a2',
            '66e59f270001629f7d4c'
        )
        return result
    } catch (error) {
        console.log('Error getting password: ', error)
    }
}

export const getPackageTypes = async () => {
    try {
        const result = await databases.listDocuments(
            '66e56789001538be65a2',
            '6779ec4000195a914929'
        )
        return result
    } catch (error) {
        console.log('Error getting package types: ', error)
    }
}

export const createPackageType = async (name) => {
    try {
        const result = await databases.createDocument(
            '66e56789001538be65a2',  // database ID
            '6779ec4000195a914929',   // collection ID for package types
            ID.unique(),
            { name }
        );
        return result;
    } catch (error) {
        console.log('Error creating package type: ', error);
        throw error;
    }
}

export const updatePackageType = async (documentId, packageType) => {
    try {
        const result = await databases.updateDocument(
            '66e56789001538be65a2',  // database ID
            '66e567a0000f222877be',   // collection ID for orders
            documentId,
            {
                packageType: packageType
            }
        );
        return result;
    } catch (error) {
        console.log('Error updating package type: ', error);
        throw error;
    }
}

export const updateCompletedTasks = async (documentId, completedTasks, isIrreversible = false) => {
    try {
        const result = await databases.updateDocument(
            '66e56789001538be65a2',  // database ID
            '66e567a0000f222877be',   // collection ID for orders
            documentId,
            {
                completed_tasks: completedTasks,
                // If task is irreversible, we'll store it in a separate array
                irreversible_tasks: isIrreversible ? completedTasks : undefined
            }
        );
        return result;
    } catch (error) {
        console.log('Error updating completed tasks: ', error);
        throw error;
    }
}

// get info about a customer from the customer collection
export const getCustomerPerson = async (documentId) => {
    try {
        const result = await databases.getDocument(
            '66e56789001538be65a2',  // database ID
            '67a1ca210020d92b1ecf',   // collection ID for customers
            documentId
        );
        return result;
    } catch (error) {
        console.log('Error getting customer: ', error);
        throw error;
    }
}

export const getCustomers = async () => {
    try {
        const result = await databases.listDocuments(
            '66e56789001538be65a2',
            '67a1ca210020d92b1ecf',
            [
                Query.limit(1000)
            ]
        )
        return result
    } catch (error) {
        console.log('Error getting customers: ', error)
        throw error;
    }
}

export const findCustomer = async (newEmail) => {
    try {
        const result = await databases.listDocuments(
            '66e56789001538be65a2',
            '67a1ca210020d92b1ecf',
            [
                Query.equal('email', [newEmail])
            ]
        )
        return result
    } catch (error) {
        console.log('Error getting customers: ', error)
        throw error;
    }
}

export const addCustomer = async (name, company, email, phone, order) => {
    try {
        const result = await databases.createDocument(
            '66e56789001538be65a2',  // database ID
            '67a1ca210020d92b1ecf',  // collection ID for customers
            ID.unique(),
            {
                name: name,
                company: company,
                email: email,
                phone: phone,
                orders: [order]
            }
        );
        return result;
    } catch (error) {
        console.log('Error adding customer: ', error);
        throw error;
    }
}

export const updateCustomerOrders = async (documentId, orders) => {
    try {
        const result = await databases.updateDocument(
            '66e56789001538be65a2',  // database ID
            '67a1ca210020d92b1ecf',  // collection ID for customers
            documentId,
            {
                orders: orders  // Assuming 'orders' is an array field in your customer document
            }
        );
        return result;
    } catch (error) {
        console.log('Error updating customer orders: ', error);
        throw error;
    }
}