import React, { useState, useEffect } from 'react'
import './Order.css'
import { addOrder, getCustomers, addCustomer, updateCustomerOrders, findCustomer } from './appwrite';
import emailjs from '@emailjs/browser';

function Order({ language }) {
  const requiredField = <span className="required-asterisk" style={{ color: 'red', marginLeft: '4px' }}>*</span>;
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    companyName: '',
    companyAddress: '',
    businessId: '',
    vatId: '',
    shippingStreet: '',
    shippingCity: '',
    shippingPostalCode: '',
    shippingCountry: '',
    hasDifferentBillingAddress: false,
    billingStreet: '',
    billingCity: '',
    billingPostalCode: '',
    billingCountry: '',
    flavorRows: [{ flavor: '', quantity: '' }],
    orderNote: ''
  });
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [showFail, setShowFail] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false);

  const translations = {
    EN: {
      title: "Making an order inquiry is as easy as filling out a contact form",
      smalltitle: "Orders",
      subtitle: "You can order our spa wafers through the following form. Contact us and we will get back to you as soon as possible with an individualized offer tailored to your needs.",
      name: "Name and surname:",
      email: "Email:",
      phone: "Phone Number:",
      companyName: "Company Name:",
      companyAddress: "Company Address:",
      businessId: "Business ID:",
      vatId: "VAT ID:",
      flavor: "Flavor:",
      quantity: "Quantity:",
      boxes: "boxes",
      shippingAddressTitle: "Shipping Address:",
      billingAddressTitle: "Billing Address:",
      differentBillingAddress: "Billing address is different from shipping address",
      street: "Street:",
      city: "City:",
      postalCode: "Postal Code:",
      country: "Country:",
      addFlavor: "+ Add another flavor",
      submit: "Place Order",
      minOrderWarning: "Minimum order is 10 boxes",
      alertMessage: "Please correct the errors in the form before submitting.",
      selectFlavor: "Select a flavor",
      flavors: {
        chocolate: "Chocolate",
        vanilla: "Vanilla",
        almond: "Almond",
        hazelnut: "Hazelnut"
      },
      validationMessages: {
        required: "This field is required",
        email: "Please enter a valid email address",
        minQuantity: "Minimum quantity is 10 boxes",
      },
      confirmationMessage: "Thank you! Your order has been successfully submitted. We will contact you soon.",
      failMessage: "There was an error submitting your order. Please try again or contact us through the contacts below.",
      note: "Note:",
      noteCharactersRemaining: "characters remaining",
      maxCharacters: "Maximum 200 characters"
    },
    CZ: {
      title: "Pro objednání stačí vyplnit kontaktní formulář",
      smalltitle: "Objednávky",
      subtitle: "Oplatky lze objednat pomocí následujícího formuláře. Kontaktujte nás a my se Vám co nejdříve ozveme s individální nabídkou přizpůsobenou Vašim potřebám.",
      name: "Jméno a příjmení:",
      email: "E-mail:",
      phone: "Telefonní číslo:",
      companyName: "Název společnosti:",
      companyAddress: "Sídlo společnosti:",
      businessId: "IČ:",
      vatId: "DIČ:",
      flavor: "Příchuť:",
      quantity: "Množství:",
      boxes: "kusů",
      shippingAddressTitle: "Doručovací adresa:",
      billingAddressTitle: "Fakturační adresa:",
      differentBillingAddress: "Fakturační adresa je jiná než doručovací",
      street: "Ulice:",
      city: "Město:",
      postalCode: "PSČ:",
      country: "Země:",
      addFlavor: "+ Přidat další příchuť",
      submit: "Odeslat objednávku",
      minOrderWarning: "Minimální objednávka je 10 kusů",
      alertMessage: "Opravte prosím chyby ve formuláři před odesláním.",
      selectFlavor: "Vyberte příchuť",
      flavors: {
        chocolate: "Čokoláda",
        vanilla: "Vanilka",
        almond: "Mandle",
        hazelnut: "Lískový ořech"
      },
      validationMessages: {
        required: "Toto pole je povinné",
        email: "Zadejte platnou e-mailovou adresu",
        minQuantity: "Minimální množství je 10 kusů",
      },
      confirmationMessage: "Děkujeme! Vaše objednávka byla úspěšně odeslána. Brzy se Vám ozveme.",
      failMessage: "Při zadávání objednávky nastala chyba. Prosím, zkuste to znovu, nebo nás kontaktuje na jednom z kontaků níže.",
      note: "Poznámka:",
      noteCharactersRemaining: "zbývajících znaků",
      maxCharacters: "Maximum 200 znaků"
    }
  };

  const t = translations[language === 'CZ' ? 'CZ' : 'EN'];

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validateField = (name, value) => {
    if (!value || value.toString().trim() === '') {
      return t.validationMessages.required;
    }
    if (name === 'email' && !validateEmail(value)) {
      return t.validationMessages.email;
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: validateField(name, value)
    }));
  };

  const handleFlavorRowChange = (index, field, value) => {
    const newFlavorRows = [...formData.flavorRows];
    newFlavorRows[index][field] = value;
    setFormData(prevState => ({
      ...prevState,
      flavorRows: newFlavorRows
    }));
    
    if (field === 'quantity' && parseInt(value) < 10) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [`quantity-${index}`]: t.validationMessages.minQuantity
      }));
    } else {
      setErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors[`quantity-${index}`];
        return newErrors;
      });
    }
  };

  const addFlavorRow = () => {
    setFormData(prevState => ({
      ...prevState,
      flavorRows: [...prevState.flavorRows, { flavor: '', quantity: '' }]
    }));
  };

  const removeFlavorRow = (index) => {
    const newFlavorRows = formData.flavorRows.filter((_, i) => i !== index);
    setFormData(prevState => ({
      ...prevState,
      flavorRows: newFlavorRows
    }));
    setErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[`quantity-${index}`];
      return newErrors;
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData(prevState => ({
        ...prevState,
        hasDifferentBillingAddress: e.target.checked
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    ['name', 'email', 'phone', 'shippingStreet', 'shippingCity', 'shippingPostalCode', 'shippingCountry'].forEach(field => {
      const error = validateField(field, formData[field]);
      if (error) newErrors[field] = error;
    });

    formData.flavorRows.forEach((row, index) => {
      if (row.flavor === '') newErrors[`flavor-${index}`] = t.validationMessages.required;
      if (row.quantity === '' || parseInt(row.quantity) < 10) {
        newErrors[`quantity-${index}`] = t.validationMessages.minQuantity;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendEmail = async (data) => {
    // EmailJS credentials
    const serviceID = 'service_1e4fxzd'; // Service ID for Zoho
    const templateID = 'template_f8h7a9z'; // Template ID for email template

    try {
      const result = await emailjs.send(serviceID, templateID, data);
      console.log('Email sent successfully!', result.text);
    } catch (error) {
      console.error('Failed to send email:', error);
      // Throw an error if email sending fails
      throw new Error('Failed to send email. Please try again.');
    }
  };

  const handleCustomerRelationship = async (name, companyName, email, phone, orderId) => {
    const foundCustomers = await findCustomer(email);
    let existingCustomer = null;
    if (foundCustomers.documents.length > 0) {
        existingCustomer = foundCustomers.documents[0]
    }
    if (existingCustomer) {
        await updateCustomerOrders(existingCustomer.$id, [...existingCustomer.orders, orderId]);
    } else {
        await addCustomer(
            name,
            companyName,
            email,
            phone,
            orderId
        );
    }
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        // Prepare submission data
        const submissionData = {
          // Personal and company information
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          companyName: formData.companyName,
          companyAddress: formData.companyAddress,
          businessId: formData.businessId,
          vatId: formData.vatId,
          
          // Shipping address
          shippingStreet: formData.shippingStreet,
          shippingCity: formData.shippingCity,
          shippingPostalCode: formData.shippingPostalCode,
          shippingCountry: formData.shippingCountry,
          
          // Billing address (if different)
          //hasDifferentBillingAddress: formData.hasDifferentBillingAddress ? true : false,
          billingStreet: formData.billingStreet,
          billingCity: formData.billingCity,
          billingPostalCode: formData.billingPostalCode,
          billingCountry: formData.billingCountry,
          
          // Order details
          order: formData.flavorRows.map(row => `${row.flavor}, ${row.quantity}`),
          note: formData.orderNote 
        };

        console.log('Submitting data:', submissionData);
        
        const res = await addOrder(submissionData);
        console.log('Appwrite response:', res);

        await handleCustomerRelationship(submissionData.name, submissionData.companyName, submissionData.email, submissionData.phone, res.$id)
        
        if (!res) {
          throw new Error('No response from Appwrite');
        }

        const emailRes = await sendEmail(submissionData);

        setShowAlert(false);
        setShowConfirmation(true);
        setShowFail(false);
        
        // Reset form
        setFormData({
          name: '',
          email: '',
          phone: '',
          companyName: '',
          companyAddress: '',
          businessId: '',
          vatId: '',
          shippingStreet: '',
          shippingCity: '',
          shippingPostalCode: '',
          shippingCountry: '',
          hasDifferentBillingAddress: false,
          billingStreet: '',
          billingCity: '',
          billingPostalCode: '',
          billingCountry: '',
          flavorRows: [{ flavor: '', quantity: '' }],
          orderNote: ''
        });
      } catch (error) {
        console.error('Error submitting order:', error);
        setShowFail(true);
        setShowAlert(false);
        setShowConfirmation(false);
      }
    } else {
      setShowAlert(true);
      setShowFail(false);
      setShowConfirmation(false);
    }
  };

  return (
    <div id="order" className="order-div">
      <span className="order-head-small">{t.smalltitle}</span>
      <span className="order-head">{t.title}</span>
      <span className="order-subtitle">{t.subtitle}</span>
      <div className='order-form-div'>
        {showAlert && (
          <div className="alert" style={{ color: 'red', marginBottom: '3em' }}>
            {t.alertMessage}
          </div>
        )}
        {showFail && (
          <div className="alert" style={{ color: 'red', marginBottom: '3em' }}>
            {t.failMessage}
          </div>
        )}
        {showConfirmation && (
          <div className="confirmation" style={{ color: 'green', marginBottom: '3em' }}>
            {t.confirmationMessage}
          </div>
        )}
        <form onSubmit={handleSubmit} noValidate>
          <div className="form-row" style={{ display: 'grid', gap: '20px' }}>
            <div className="form-group name-group">
              <label htmlFor="name">{t.name}{requiredField}</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <span className="error-message">{errors.name}</span>}
            </div>
            <div className="form-group company-group">
              <label htmlFor="companyName">{t.companyName}</label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-row" style={{ display: 'grid', gap: '20px' }}>
            <div className="form-group email-group">
              <label htmlFor="email">{t.email}{requiredField}</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <span className="error-message">{errors.email}</span>}
            </div>
            <div className="form-group company-address-group">
              <label htmlFor="companyAddress">{t.companyAddress}</label>
              <input
                type="text"
                id="companyAddress"
                name="companyAddress"
                value={formData.companyAddress}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-row" style={{ display: 'grid', gap: '20px' }}>
            <div className="form-group phone-group">
              <label htmlFor="phone">{t.phone}{requiredField}</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && <span className="error-message">{errors.phone}</span>}
            </div>
            <div className="form-group business-vatid-row" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
              <div className="form-group business-id-group">
                <label htmlFor="businessId">{t.businessId}</label>
                <input
                  type="text"
                  id="businessId"
                  name="businessId"
                  value={formData.businessId}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group vat-id-group">
                <label htmlFor="vatId">{t.vatId}</label>
                <input
                  type="text"
                  id="vatId"
                  name="vatId"
                  value={formData.vatId}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {formData.flavorRows.map((row, index) => (
            <div key={index} className='row-form-group'>
              <div className="form-group flavor-group">
                <label htmlFor={`flavor-${index}`}>{t.flavor}</label>
                <select
                  id={`flavor-${index}`}
                  name={`flavor-${index}`}
                  value={row.flavor}
                  onChange={(e) => handleFlavorRowChange(index, 'flavor', e.target.value)}
                >
                  <option value="">{t.selectFlavor}</option>
                  {Object.entries(t.flavors).map(([value, label]) => (
                    <option key={value} value={value}>{label}</option>
                  ))}
                </select>
                {errors[`flavor-${index}`] && <span className="error-message">{errors[`flavor-${index}`]}</span>}
              </div>
              <div className="form-group quantity-group">
                <label htmlFor={`quantity-${index}`}>{t.quantity}</label>
                <div className='quantity-group-inside'>
                  <input
                    type="number"
                    id={`quantity-${index}`}
                    name={`quantity-${index}`}
                    value={row.quantity}
                    onChange={(e) => handleFlavorRowChange(index, 'quantity', e.target.value)}
                    min="10"
                  />
                  <span>{t.boxes}</span>
                  {index > 0 && (
                    <button type="button" className="remove-row" onClick={() => removeFlavorRow(index)}>X</button>
                  )}
                </div>
                {errors[`quantity-${index}`] && <span className="error-message">{errors[`quantity-${index}`]}</span>}
              </div>
            </div>
          ))}
          <button type="button" className="add-row" onClick={addFlavorRow}>{t.addFlavor}</button>

          <div className="form-group" style={{ marginTop: '40px' }}>
            <label htmlFor="orderNote">
              {t.note}
            </label>
            <textarea
              id="orderNote"
              name="orderNote"
              value={formData.orderNote}
              onChange={(e) => {
                const value = e.target.value.slice(0, 200);
                setFormData(prev => ({
                  ...prev,
                  orderNote: value
                }));
              }}
              maxLength={200}
              rows={4}
              style={{ width: '100%', resize: 'vertical' }}
            />
            <div style={{ textAlign: 'right', fontSize: '0.8em', color: '#666' }}>
              {200 - formData.orderNote.length} {t.noteCharactersRemaining}
            </div>
          </div>

          <div className="shipping-address-section" style={{ marginTop: '40px' }}>
            <h3 className="section-title">{t.shippingAddressTitle}</h3>
            <div className="form-row" style={{ display: 'grid', gap: '20px' }}>
              <div className="form-group street-group">
                <label htmlFor="shippingStreet">{t.street}{requiredField}</label>
                <input
                  type="text"
                  id="shippingStreet"
                  name="shippingStreet"
                  value={formData.shippingStreet}
                  onChange={handleChange}
                />
                {errors.shippingStreet && <span className="error-message">{errors.shippingStreet}</span>}
              </div>
              <div className="form-group city-group">
                <label htmlFor="shippingCity">{t.city}{requiredField}</label>
                <input
                  type="text"
                  id="shippingCity"
                  name="shippingCity"
                  value={formData.shippingCity}
                  onChange={handleChange}
                />
                {errors.shippingCity && <span className="error-message">{errors.shippingCity}</span>}
              </div>
            </div>
            <div className="form-row" style={{ display: 'grid', gap: '20px', marginTop: '20px' }}>
              <div className="form-group postal-code-group">
                <label htmlFor="shippingPostalCode">{t.postalCode}{requiredField}</label>
                <input
                  type="text"
                  id="shippingPostalCode"
                  name="shippingPostalCode"
                  value={formData.shippingPostalCode}
                  onChange={handleChange}
                />
                {errors.shippingPostalCode && <span className="error-message">{errors.shippingPostalCode}</span>}
              </div>
              <div className="form-group country-group">
                <label htmlFor="shippingCountry">{t.country}{requiredField}</label>
                <input
                  type="text"
                  id="shippingCountry"
                  name="shippingCountry"
                  value={formData.shippingCountry}
                  onChange={handleChange}
                />
                {errors.shippingCountry && <span className="error-message">{errors.shippingCountry}</span>}
              </div>
            </div>

            <div className="form-group" style={{ marginTop: '20px' }}>
              <label className="checkbox-label" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <input
                  type="checkbox"
                  checked={formData.hasDifferentBillingAddress}
                  onChange={handleCheckboxChange}
                  style={{ marginRight: '10px' }}
                />
                {t.differentBillingAddress}
              </label>
            </div>

            {formData.hasDifferentBillingAddress && (
              <div className="billing-address-section" style={{ marginTop: '20px' }}>
                <h3 className="section-title">{t.billingAddressTitle}</h3>
                <div className="form-row" style={{ display: 'grid', gap: '20px' }}>
                  <div className="form-group street-group">
                    <label htmlFor="billingStreet">{t.street}</label>
                    <input
                      type="text"
                      id="billingStreet"
                      name="billingStreet"
                      value={formData.billingStreet}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group city-group">
                    <label htmlFor="billingCity">{t.city}</label>
                    <input
                      type="text"
                      id="billingCity"
                      name="billingCity"
                      value={formData.billingCity}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row" style={{ display: 'grid', gap: '20px', marginTop: '20px' }}>
                  <div className="form-group postal-code-group">
                    <label htmlFor="billingPostalCode">{t.postalCode}</label>
                    <input
                      type="text"
                      id="billingPostalCode"
                      name="billingPostalCode"
                      value={formData.billingPostalCode}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group country-group">
                    <label htmlFor="billingCountry">{t.country}</label>
                    <input
                      type="text"
                      id="billingCountry"
                      name="billingCountry"
                      value={formData.billingCountry}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <button type="submit" className="submit-button">{t.submit}</button>
        </form>
      </div>
    </div>
  )
}

export default Order