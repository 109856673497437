import React, { useState, useEffect } from 'react';
import './DbRoute.css'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { getCustomerPerson } from './appwrite';

function DbRouteCustomerDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [phone, setPhone] = useState('')
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const handleBack = () => {
    navigate('/objednavky', { state: { isAuthenticated: true } });
  };

  useEffect(() => {
      const fetchOrders = async () => {
          try {
              const customer = await getCustomerPerson(id);
              setName(customer.name)
              setEmail(customer.email)
              setPhone(customer.phone)
              setCompanyName(customer.company)
              setOrders(customer.orders);
              setLoading(false);
          } catch (err) {
              setError(`Error fetching orders: ${err.message}`);
              setLoading(false);
              console.error('Error fetching orders:', err);
          }
      };

      fetchOrders();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
  
    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
  
    // Format as dd.mm.yyyy
    return `${day}.${month}.${year}`;
  };

  const flavorTranslations = {
    chocolate: "Čokoláda",
    cocoa: "Kakao",
    apple: "Jablko",
    coconut: "Kokos",
    almond: "Mandle",
    hazelnut: "Lískový ořech",
    vanilla: "Vanilka"
  };

  const translateOrderItem = (item) => {
    const [flavor, quantity] = item.split(', ');
    const translatedFlavor = flavorTranslations[flavor.toLowerCase()] || flavor;
    return `${translatedFlavor}, ${quantity}`;
  };

  const translateDefault = (item) => {
    if (item === 'Default') { return 'Nezadaný' }
    return item
  }

  return (
    <>
      <button
        onClick={handleBack}
        className="back-button"
      >
        ← Zpět na seznam
      </button>

      <div className='order-container'>
        <div className="detail-container">        
          <div className="customer-details-info">
            <div>
              <h3>Jméno:</h3>
              <p>{name}</p>          
            </div>
            <div>
              <h3>Email:</h3>
              <p>{email}</p>
            </div>
            <div>
              <h3>Telefon:</h3>
              <p>{phone}</p>
            </div>
          </div>
          <div>
          <div>
              <h3>Název společnosti:</h3>
              <p>{companyName}</p>
            </div>
          </div>
        </div>

        <div className='customer-order-list-container'>
          {orders.map((item, i) => (
            <div className='customer-order-list'>
              <div className='customer-order-list-header'>
                <p>Objednávka {i+1}: {formatDate(item.$createdAt)}</p>
                <Link to={`/detail/${item.$id}`} className='customer-order-link'>Přejít na objednávku</Link>
              </div>
              <span className='customer-order-item'>Obal: {translateDefault(item.packageType)}</span>
              <ul>
                {item.order.map((orderItem, orderItemIndex) => (
                  <li key={orderItemIndex} className='customer-order-item'>{translateOrderItem(orderItem)}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      {/*
      <div className='orders-list'>
          {orders.map(orderId => (
              <div key={orderId} className='order-item'>
                  <Link to={`/detail/${orderId}`}>Order {orderId}</Link>
              </div>
          ))}
      </div>
      */}
    </>
  );
}

export default DbRouteCustomerDetail
