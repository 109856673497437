import React, { useState, useEffect } from 'react';
import './DbRoute.css'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { getPackageTypes, updatePackageType, createPackageType, updateCompletedTasks, getCustomer } from './appwrite';
import { Check } from 'lucide-react';

function DbRouteDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [customerData, setCustomerData] = useState(location.state?.customer || null);
  const [packageTypes, setPackageTypes] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPackageType, setNewPackageType] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [irreversibleTasks, setIrreversibleTasks] = useState([]);
  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    taskDescription: '',
  });
  const handleBack = () => {
    navigate('/objednavky', { state: { isAuthenticated: true } });
  };

  // Fetch fresh customer data
  const fetchCustomerData = async () => {
    try {
      const result = await getCustomer(id);
      setCustomerData(result);
      setSelectedPackage(result.packageType);
      setCompletedTasks(result.completed_tasks || []);
      setIrreversibleTasks(result.irreversible_tasks || []);
    } catch (err) {
      setError('Failed to load customer data');
      console.error('Error loading customer data:', err);
    }
  };

  // Initial data fetch
  useEffect(() => {
    fetchCustomerData();
  }, [id]);

  const IRREVERSIBLE_TASKS = [
    'Potvrdit přípravu k expedici',
    'Potvrdit odeslání'
  ];

  const flavorTranslations = {
    chocolate: "Čokoláda",
    cocoa: "Kakao",
    apple: "Jablko",
    coconut: "Kokos",
    almond: "Mandle",
    hazelnut: "Lískový ořech",
    vanilla: "Vanilka"
  };

  const translateOrderItem = (item) => {
    const [flavor, quantity] = item.split(', ');
    const translatedFlavor = flavorTranslations[flavor.toLowerCase()] || flavor;
    return `${translatedFlavor}, ${quantity}`;
  };

  const handleTaskToggle = async (taskDescription) => {
    // Check if task is irreversible
    if (IRREVERSIBLE_TASKS.includes(taskDescription)) {
      setConfirmationDialog({
        isOpen: true,
        taskDescription
      });
      return;
    }

    try {
      let updatedTasks;
      if (completedTasks.includes(taskDescription)) {
        updatedTasks = completedTasks.filter(task => task !== taskDescription);
      } else {
        updatedTasks = [...completedTasks, taskDescription];
      }

      await updateCompletedTasks(customerData.$id, updatedTasks);
      await fetchCustomerData(); // Fetch fresh data after update
    } catch (err) {
      setError('Failed to update task status');
      console.error('Error updating task status:', err);
    }
  };

  const handleConfirmIrreversibleTask = async () => {
    const taskDescription = confirmationDialog.taskDescription;
    try {
      const updatedTasks = [...completedTasks, taskDescription];
      const updatedIrreversibleTasks = [...irreversibleTasks, taskDescription];
      
      await updateCompletedTasks(customerData.$id, updatedTasks, true);
      await fetchCustomerData(); // Fetch fresh data after update
    } catch (err) {
      setError('Failed to update task status');
      console.error('Error updating task status:', err);
    } finally {
      setConfirmationDialog({ isOpen: false, taskDescription: '' });
    }
  };

  useEffect(() => {
    const fetchPackageTypes = async () => {
      try {
        setIsLoading(true);
        const result = await getPackageTypes();
        if (result && result.documents) {
          setPackageTypes(result.documents);
        }
      } catch (err) {
        setError('Failed to load package types');
        console.error('Error loading package types:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPackageTypes();
  }, []);

  const handlePackageChange = async (newPackageType) => {
    try {
      setIsUpdating(true);
      await updatePackageType(customerData.$id, newPackageType);
      await fetchCustomerData(); // Fetch fresh data after update
    } catch (err) {
      setError('Failed to update package type');
      console.error('Error updating package type:', err);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCreatePackageType = async (e) => {
    e.preventDefault();
    if (!newPackageType.trim()) return;

    try {
      setIsCreating(true);
      // Create new package type
      const result = await createPackageType(newPackageType.trim());
      
      // Update package types list
      setPackageTypes(prev => [...prev, result]);
      
      // Update current item's package type
      await handlePackageChange(result.name);
      
      // Close modal and reset form
      setIsModalOpen(false);
      setNewPackageType('');
    } catch (err) {
      setError('Failed to create new package type');
      console.error('Error creating package type:', err);
    } finally {
      setIsCreating(false);
    }
  };

  // If no customer data is found, show error or redirect
  if (!customerData) {
    return (
      <div className="p-4">
        <p>Loading customer data...</p>
      </div>
    );
  }

  return (
    <>
      <button
        onClick={handleBack}
        className="back-button"
      >
        ← Zpět na seznam
      </button>
      <div className='order-container'>
        <div className="detail-container">        
          <div className="customer-details-info">
            <div>
              <h3>Jméno:</h3>
              <p>{customerData.name}</p>          
            </div>
            <div>
              <h3>Email:</h3>
              <p>{customerData.email}</p>
            </div>
            <div>
              <h3>Telefon:</h3>
              <p>{customerData.phone}</p>
            </div>
            <div className='order-link-header-div'>
              <Link to={`/customer/${customerData.customer.$id}`} className='customer-order-link'>Přejít na zákazníka</Link>
            </div>
          </div>

          <div className='customer-details-info'>
            <div>
              <h3>Název společnosti:</h3>
              <p>{customerData.companyName}</p>
            </div>
            <div>
              <h3>Sídlo společnosti:</h3>
              <p>{customerData.companyAddress}</p>
            </div>
            <div>
              <h3>IČ:</h3>
              <p>{customerData.businessId}</p>
            </div>
            <div>
              <h3>DIČ:</h3>
              <p>{customerData.vatId}</p>
            </div>
          </div>

          <div className='customer-details-info'>
            <div>
              <h3>Doručovací adresa:</h3>
              <div>
                <span>{customerData.shippingStreet}</span><br />
                <span>{customerData.shippingCity}</span><br />
                <span>{customerData.shippingPostalCode}</span><br />
                <span>{customerData.shippingCountry}</span>
              </div>
            </div>

            <div>
              <h3>Fakturační adresa:</h3>
              <div>
                <span>{customerData.billingStreet}</span><br />
                <span>{customerData.billingCity}</span><br />
                <span>{customerData.billingPostalCode}</span><br />
                <span>{customerData.billingCountry}</span>
              </div>
            </div>
          </div>

          <div className='customer-details-info'>  
            <div>
              <h3 className="font-semibold">Objedávka:</h3>
              <ul className="list-disc pl-5">
                {Array.isArray(customerData.order) ? (
                  customerData.order.map((item, index) => (
                    <li key={index}>{translateOrderItem(item)}</li>
                  ))
                ) : (
                  <li>No order details available</li>
                )}
              </ul>
            </div>
          
          </div>

          <div className='customer-details-info'>
            <div>
              <h3>Poznámka:</h3>
              <div>
                <span>{customerData.note}</span><br />
              </div>
            </div>
          </div>
        </div>

        <div className='tasks-container'>
          <div className="package-types-div">
            <h3>Typ obalu: </h3>
            {isLoading ? (
              <span>Načítání...</span>
            ) : error ? (
              <span>{error}</span>
            ) : (
              <div>
                <select
                  value={selectedPackage}
                  onChange={(e) => handlePackageChange(e.target.value)}
                  disabled={isUpdating}
                >
                  <option value="Default">Nezadaný</option>
                  {packageTypes.map((type) => (
                    <option key={type.$id} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
                {isUpdating && <span>Načítání...</span>}
              </div>
            )}
          </div>
          <div className="add-package-type-div">
            <button
              onClick={() => setIsModalOpen(true)}
              className="add-package-type-btn"
            >
              + Přidat typ obalu
            </button>
          </div>

          <div className="tasks-div">
            <h3>Seznam úkolů:</h3>
            <div className='task'>
              <span>Poslat email o přijetí objednávky</span>
              <Check 
                className={`check ${
                  completedTasks.includes('Poslat email o přijetí objednávky') 
                    ? 'check-green' 
                    : 'check-gray'
                }`}
                onClick={() => handleTaskToggle('Poslat email o přijetí objednávky')}
                size={24}
              />
            </div>
            <div className='task'>
              <span>Stanovit typ obalu</span>
              <Check 
                className={`check ${
                  completedTasks.includes('Stanovit typ obalu') 
                    ? 'check-green' 
                    : 'check-gray'
                }`}
                onClick={() => handleTaskToggle('Stanovit typ obalu')}
                size={24}
              />
            </div>
            <div className='task'>
              <span>Dát informaci do výroby</span>
              <Check 
                className={`check ${
                  completedTasks.includes('Dát informaci do výroby') 
                    ? 'check-green' 
                    : 'check-gray'
                }`}
                onClick={() => handleTaskToggle('Dát informaci do výroby')}
                size={24}
              />
            </div>
            <div className='task'>
              <span>Stanovit termín přípravy k expedici</span>
              <Check 
                className={`check ${
                  completedTasks.includes('Stanovit termín přípravy k expedici') 
                    ? 'check-green' 
                    : 'check-gray'
                }`}
                onClick={() => handleTaskToggle('Stanovit termín přípravy k expedici')}
                size={24}
              />
            </div>
            <div className='task'>
              <span>Potvrdit přípravu k expedici</span>
              <Check 
                className={`check ${
                  irreversibleTasks.includes('Potvrdit přípravu k expedici')
                    ? 'check-disabled check-green'
                    : completedTasks.includes('Potvrdit přípravu k expedici')
                    ? 'check-green'
                    : 'check-gray'
                }`}
                onClick={() => !irreversibleTasks.includes('Potvrdit přípravu k expedici') && 
                  handleTaskToggle('Potvrdit přípravu k expedici')}
                size={24}
              />
            </div>
            <div className='task'>
              <span>Potvrdit odeslání</span>
              <Check 
                className={`check ${
                  irreversibleTasks.includes('Potvrdit odeslání')
                    ? 'check-disabled check-green'
                    : completedTasks.includes('Potvrdit odeslání')
                    ? 'check-green'
                    : 'check-gray'
                }`}
                onClick={() => !irreversibleTasks.includes('Potvrdit odeslání') && 
                  handleTaskToggle('Potvrdit odeslání')}
                size={24}
              />
            </div>
          </div>

          {/* Confirmation Dialog */}
          {confirmationDialog.isOpen && (
            <>
              <div className="confirmation-overlay" />
              <div className="confirmation-dialog">
                <h4 className="confirmation-title">Potvrzení akce</h4>
                <p>Opravdu chcete označit tento úkol jako dokončený? Tuto akci nelze vrátit zpět.</p>
                <div className="confirmation-buttons">
                  <button
                    className="confirmation-button confirmation-button-cancel"
                    onClick={() => setConfirmationDialog({ isOpen: false, taskDescription: '' })}
                  >
                    Zrušit
                  </button>
                  <button
                    className="confirmation-button confirmation-button-confirm"
                    onClick={handleConfirmIrreversibleTask}
                  >
                    Potvrdit
                  </button>
                </div>
              </div>
            </>
          )}
        </div>


        {/* Modal Overlay */}
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3 className="modal-title">Přidat nový typ obalu</h3>
              <form onSubmit={handleCreatePackageType} className="modal-form">
                <input
                  type="text"
                  value={newPackageType}
                  onChange={(e) => setNewPackageType(e.target.value)}
                  placeholder="Vložte název nového typu obalu"
                  className="modal-input"
                  disabled={isCreating}
                />
                <div className="modal-buttons">
                  <button
                    type="button"
                    onClick={() => setIsModalOpen(false)}
                    className="modal-button modal-button-cancel"
                    disabled={isCreating}
                  >
                    Zrušit
                  </button>
                  <button
                    type="submit"
                    className="modal-button modal-button-confirm"
                    disabled={isCreating || !newPackageType.trim()}
                  >
                    {isCreating ? 'Přidávání...' : 'Přidat'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DbRouteDetail;